<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="新增车辆"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="车牌号">
          <a-input
            v-decorator="['car_no', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入车牌号' },
                { max: 20, message: '最多20个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>

        <a-form-item label="图片">
          <span
            class="tip-text"
          >支持png/jpg格式照片，10M以内, 最多1张
          </span>
          <upload-image
            :is-add-watermark="isAddWatermark"
            accept=".jpg,.jpeg,.png"
            :max-count="1"
            @change="handleUploadImage"
            v-decorator="['attachment',{
              rules:[
                {validator: checkAttachments}
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="工作地点">
          <a-select
            @focus="fetchFuneralHomeOptions"
            :loading="loadingFuneralHomeOptions"
            mode="multiple"
            v-decorator="['funeral_home_ids', {
              rules: [{ required: true, message: '请选择工作地点' }]
            }]"
          >
            <a-select-option
              v-for="home in funeralHomeOptions"
              :key="home.id"
              :value="home.id"
            >
              {{ home.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="介绍">
          <a-input
            v-decorator="['remark', {
              rules: [
                { message: '请输入介绍' },
                { max: 200, message: '最多200个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { createCar } from '@/api/car'
import UploadImage from '@/components/Upload/Image'
import { findFuneralHomeOptions } from '@/api/funeral_home'

export default {
  name: 'NewCar',
  components: {
    UploadImage
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'car_new' }),
      submitting: false,
      funeralHomeOptions: [],
      loadingFuneralHomeOptions: false,
      isAddWatermark: false,
      attachments: [] // 图片
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    handleUploadImage(fileList) {
      this.attachments = fileList
    },

    // 检查照片
    checkAttachments(rule, value, callback) {
      for (const key in this.attachments) {
        if (this.attachments[key] && this.attachments[key].status === 'error') {
          // eslint-disable-next-line standard/no-callback-literal
          callback('照片上传错误')
          return
        }
      }

      callback()
    },

    fetchFuneralHomeOptions() {
      this.loadingFuneralHomeOptions = true
      findFuneralHomeOptions().then((res) => {
        this.funeralHomeOptions = res.data.options
      })
      this.loadingFuneralHomeOptions = false
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      if (this.$lodash.find(this.attachments, { status: 'uploading' })) {
        this.$warning({
          title: '文件尚未上传完成',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          if (typeof this.attachments === 'object' && this.attachments[0]) {
            values.attachment = this.attachments[0].response
          } else {
            delete values.attachment
          }
          createCar(values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
